<template>
  <div>
    <div class="auth-wrapper authentcation-auth auth-v1 px-2">
      <div class="auth-inner py-2" v-if="setting_auth">
        <b-card class="mb-0 pt-2">
          <b-card-title class="mb-1 text-center pt-2 pb-2">
            {{ $t("auth.login") }}
          </b-card-title>

          <!--  -->

          <template>
            <!-- form -->
            <validation-observer ref="loginForm" #default="{ invalid }">
              <b-form class="auth-login-form mt-2" @submit.prevent="validationForm">
                <b-tabs
                  class="bg-eef3f4"
                  :nav-class="{
                    'no-title': !setting_auth.verifiy_by_mobile || !setting_auth.email_verification
                  }"
                  v-model="tabIndex"
                  @changed="updateLoginBy"
                >
                  <b-tab
                    v-if="setting_auth.verifiy_by_mobile"
                    :title-item-class="`email_verification_${setting_auth.email_verification}`"
                  >
                    <template v-if="setting_auth.email_verification" #title>
                      <feather-icon icon="PhoneIcon" />
                      <span>برقم الجوال </span>
                    </template>
                    <div class="pt-3">
                      <b-form-group label="رقم الجوال" label-for="phone">
                        <validation-provider #default="{ errors }" name="phone" ref="phoneFieldValidator" :rules="rulesPhone">
                          <!--   -->
                          <vue-tel-input
                            v-model="formData.phone_number"
                            mode="national"
                            :autoFormat="false"
                            :validCharactersOnly="true"
                            :defaultCountry="defaultCountry"
                            :onlyCountries="countres"
                            :invalidMsg="errors[0]"
                            :dropdownOptions="{
                              disabled: false,
                              tabindex: 0,
                              showDialCodeInSelection: true,
                              showDialCode: true,
                              showFlags: true
                            }"
                            :inputOptions="{
                              autocomplete: 'off',
                              readonly: false,
                              maxlength: 12,
                              name: 'phone',
                              id: 'phone',
                              type: 'tel',
                              required: formData.authentcate_by == 'phone',
                              placeholder: 'رقم التلفون '
                            }"
                            name="phone"
                            @validate="updateFormData"
                            @country-changed="updateFormData"
                            wrapperClasses="phone-wrapper"
                            placeholder="رقم التلفون "
                          >
                            <template #arrow-icon="{ open }">
                              <span class="vti__dropdown-arrow">{{ open ? "▲" : "▼" }}</span>
                            </template>
                          </vue-tel-input>
                          <!--    :dropdownOptions="{
       disabled:false,
       tabindex:0,
       showDialCodeInSelection:true,
       showDialCode:true,
       showFlags:true,
   }" -->

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-tab>
                  <b-tab
                    v-if="setting_auth.email_verification"
                    :title-item-class="`verifiy_by_mobile_${setting_auth.verifiy_by_mobile}`"
                  >
                    <template #title>
                      <feather-icon icon="MailIcon" />
                      <span>{{ $t("auth.email") }}</span>
                    </template>
                    <div class="pt-3">
                      <b-form-group label-for="email" name="email" :label="$t('auth.email')">
                        <validation-provider #default="{ errors }" name="email" :label="$t('auth.email')" :rules="rulesEmail">
                          <b-form-input
                            id="email"
                            v-model="formData.email"
                            name="login-email"
                            :state="errors.length > 0 ? false : null"
                            placeholder="john@example.com"
                            autofocus
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-tab>
                </b-tabs>

                <b-form-group>
                  <b-form-checkbox id="by_verify_code" v-model="formData.by_verify_code" name="by_verify_code">
                    الدخول عن طريق كود التحقق
                  </b-form-checkbox>
                </b-form-group>
                <!-- password -->
                <template v-if="!loginByVeryfiy">
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label for="password">{{ $t("auth.password") }}</label>
                      <!--  <b-link :to="{name:'auth-forgot-password'}">
                  <small>{{ translate('Forgot Password?')}}</small>
                </b-link> -->
                    </div>
                    <validation-provider #default="{ errors }" name="password" rules="required">
                      <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                        <b-form-input
                          id="password"
                          v-model="formData.password"
                          :type="passwordFieldType"
                          class="form-control-merge"
                          autocomplete="off"
                          :state="errors.length > 0 ? false : null"
                          name="login-password"
                          :placeholder="$t('auth.password')"
                        />

                        <b-input-group-append is-text>
                          <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- checkbox -->
                  <b-form-group>
                    <b-form-checkbox id="remember-me" v-model="formData.remember" name="checkbox-1">
                      {{ $t("auth.remember_me") }}
                    </b-form-checkbox>
                  </b-form-group>
                </template>

                <!-- <div id="recaptcha-container"></div> -->

                <b-button variant="primary" type="submit" block :disabled="invalid || submitProcessing">
                  <b-spinner small type="grow" v-if="submitProcessing"></b-spinner>
                  {{ loginByVeryfiy ? " ارسال رمز التحقق" : $t("auth.login") }}
                </b-button>
              </b-form>
            </validation-observer>

            <b-card-text class="text-center mt-2">
              <span>{{ $t("auth.do_you_have_account") }}</span>
              <b-link
                :to="{
                  name: 'auth-register',
                  params: {
                    language: prefix_url
                  }
                }"
              >
                <span>{{ $t("auth.sign_up") }}</span>
              </b-link>
            </b-card-text>
          </template>
        </b-card>
        <!-- /Login v1 -->
      </div>
    </div>

    <template v-if="step_number > 0">
      <!-- <verify
        v-if="verify_sms_sa || tabIndex == 1"
        :token="activation_token"
        :formData="formData"
        :form_type="formData.form_type"
        @restAll="restAll"
        :countres="countres"
        @updateTabIndex="(newVale) => (step_number = newVale)"
      /> -->

      <!-- <VerifyFirebase
        v-if="
          setting_auth.enabled_sms_google && !verify_sms_sa && tabIndex == 0
        "
        :countres="countres"
        :formData="formData"
        :appVerifier="recaptchaVerifier"
        :form_type="formData.form_type"
        @updateTabIndex="(newVale) => (step_number = newVale)"
      /> -->

      <!-- @updateVerifyForm="" -->
    </template>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from "vee-validate";
//import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BButton,
  BCard,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
  BSpinner,
  BTab,
  BTabs
} from "bootstrap-vue";

import { utilsMixin } from "@core/mixins/AuthForm/index.js";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

//import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import { VueTelInput } from "vue-tel-input";
//import { VueRecaptcha } from "vue-recaptcha";
import Ripple from "vue-ripple-directive";

//import VerifyFirebase from "./VerifyFirebase.vue";
import "./style.css";
//import verify from "./verify.vue";
import useJwt from "@/auth/jwt/useJwt";

// import {
//   createRecaptchaVerifier,
//   isCaptchaOK,
//   isExpireToken,
//   sendVerificationCode,
// } from "@/libs/firebase/PhoneAuthHelper.js";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCard,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BFormSelect,
    BTab,
    BTabs,
    BFormInvalidFeedback,
    BSpinner,
    // VuexyLogo,
    ValidationProvider,
    ValidationObserver,

    VueTelInput
    // VueRecaptcha,
    //  verify,
    // VerifyFirebase,
  },
  directives: {
    Ripple
  },

  mixins: [togglePasswordVisibility, utilsMixin],
  data() {
    return {
      password: "",
      confirmationResult: undefined,
      enable_firebase: true,
      recaptchaVerifier: true,
      current_component: "login",

      formData: {
        //  phone_number:"",
        recaptcha: undefined,
        by_verify_code: false,
        form_type: "login",
        authentcate_by: "phone",
        phone_number: "",
        phone: "",
        email: "",
        password: "",
        remember: false
      },

      fromStep2: {
        password: "",
        passwordCon: ""
      }
    };
  },

  computed: {
    loginByVeryfiy() {
      return this.formData.by_verify_code == true;
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    rulesEmail() {
      return this.formData.authentcate_by === "email" ? "required|email" : undefined;
    },
    rulesPhone() {
      return this.formData.authentcate_by === "phone" ? "required|phone_validate" : undefined;
    }

    // rulesRecaptcha() {
    //   this.formData.recaptcha = this.recaptchaData;
    //   return !isCaptchaOK() ? "required" : undefined;
    // }
  },
  mounted() {
    if (this.setting_auth.verifiy_by_mobile == true) {
      this.formData.authentcate_by = "phone";
    } else {
      this.formData.authentcate_by = "email";
    }
    const self = this;
    /*   if (this.setting_auth.enabled_sms_google) {
      setTimeout(() => {
        //  generateRecaptcha();
        //   this.phoneAuthHandler=new PhoneAuthHandler(firebaseConfig);
        // this.recaptchaVerifier=this.phoneAuthHandler.createRecaptchaVerifier();
      }, 1200);

      // Send verification code

      // this.regenrateCaptcha();
    } */

    // this.$refs.loginForm.validate();
  },
  methods: {
    verifyMethod(response) {
      this.formData.recaptcha = response;
    },
    expiredMethod(response) {
      this.formData.recaptcha = undefined;
    },
    renderMethod(response) {},
    errorMethod() {
      this.formData.recaptcha = undefined;
    },
    // regenrateCaptcha() {
    //   try {
    //     const recaptchaVerifier = createRecaptchaVerifier();
    //     // Handle success
    //     this.recaptchaVerifier = recaptchaVerifier;
    //   } catch (error) {
    //     // Handle error
    //     this.showToast(error.message, "danger");
    //     if (isExpireToken(error.code)) {
    //   //    this.regenrateCaptcha();
    //     } else if (error.code == "auth/error-code") {
    //       this.showToast("عفواً لم يتم ارسال رمز التحقق لهذا الرقم .", "danger");
    //     }

    //     console.error("Error while checking the verification code", error);
    //   }
    // },

    updateLoginBy() {
      // console.log('data',data)
      if (this.setting_auth.verifiy_by_mobile == true && this.tabIndex == 0) {
        this.formData.authentcate_by = "phone";
      } else {
        this.formData.authentcate_by = "email";
      }
      // this.formData.authentcate_by=this.tabIndex==0?'phone':'email';
    },

    validationForm() {
      const self = this;
      // this.$refs.recaptcha.execute();
      //  this.$refs.recaptcha.reset();

      // if(this.captchaVerify==false){
      //        this.showToast("يجب التحقق انك لست روبوت", 'danger')
      //        return false;
      // }
      // if(isCaptchaOK()==true){
      //     this.formData.recaptcha=true;
      // }else{
      //      this.formData.recaptcha=undefined;
      // }
      let isValidPhone = this.isValidPhone;

      if (!this.isValidPhone) {
        const errors = ["يجب ادخال رقم الجوال بشكل صحيح"];
        this.$refs.phoneFieldValidator.setErrors(errors);
        this.showToast("يجب ادخال رقم الجوال بشكل صحيح", "danger");
        return false;
      }

      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.detectEnablePath();
        } else {
          this.showToast("يجب التحقق من الحقول", "danger");
        }
      });
    },
    // telData
    async sendToFireBase(phone, msg) {
      // let formData=this.formData;
      //  formData=this.getFormData(formData);

      // Send verification code
      try {
        const verificationId = await sendVerificationCode(phone, this.recaptchaVerifier);
        // Handle success
        this.submitProcessing = false;
        this.step_number = 1;
        //    this.confirmationResult = verificationId;
        this.recaptchaVerifier = verificationId;
        this.showToast(msg, "success");
      } catch (error) {
        this.submitProcessing = false;
        if (isExpireToken(error.code)) {
          //  this.regenrateCaptcha();
        }
        if (error.code == "auth/too-many-requests") {
          this.showToast(
            "لقد حظرنا جميع الطلبات الواردة من هذا الجهاز بسبب نشاط غير عادي. حاول مرة أخرى في وقت لاحق.",
            "danger"
          );
        } else {
          this.showToast(error.message, "danger");
        }
        console.error("Error while checking the verification code", error);
      }

      //  sendOtpForVerification({
      // phoneNumber:phone,
      //  success: (res) => {
      //         this.submitProcessing = false
      //        this.step_number=1;
      //         this.confirmationResult = res;
      //          this.showToast(msg, 'success')
      //       },
      //         error: (error) => {
      //           this.submitProcessing = false

      //             if(error.code=="auth/too-many-requests"){
      //                  this.showToast("لقد حظرنا جميع الطلبات الواردة من هذا الجهاز بسبب نشاط غير عادي. حاول مرة أخرى في وقت لاحق.", 'danger')

      //             }else{
      //                       this.showToast(error.message, 'danger')
      //             }
      //           console.error('Error while checking the verification code', error);

      //   }

      //   });
    },

    detectEnablePath() {
      let formData = this.formData;

      if (this.formData.authentcate_by == "phone") {
        formData = this.getFormData(formData);
      }
      this.submitProcessing = true;
      this.login(formData);

      return true;
    },

    login(formData) {
      this.submitProcessing = true;
      // let formData=this.formData;
      //`${APP_URL_PAGE}/login`

      this.$http
        .post(`${this.prefixApi}/login`, formData)
        .then(response => {
          let data = response.data;
          let message = data.msg ? data.msg : data.message;
          this.submitProcessing = false;
          //  console.log("data", data);
          //  console.log("access_token", data.access_token);
          if (response.data.status === true) {
            this.showToast(message, "success");
            if (data.access_token) {
              this.disableButton = true;
              this.disableVerfiyButton = true;
              let accessToken = response.data.access_token;
              let userData = response.data.user;

              useJwt.setToken(response.data.access_token);
              useJwt.setRefreshToken(response.data.access_token);
              localStorage.setItem("userData", JSON.stringify(userData));
              localStorage.setItem("accessToken", accessToken);
              this.$store.dispatch("setUser", userData);
              this.$router.push({ name: "dashboard.index" });
            } else {
              var newData = {
                ...formData,
                ...data
              };
              this.$store.dispatch("setDataAuth", newData);

              if (data.token) {
                this.activation_token = data.token;
              }
              this.$router.push({
                name: "auth-verify",
                params: { verfiy_token: this.activation_token }
              });
            }

            //  this.$router.push({ name: 'auth-login' })
          } else {
            this.showToast(message, "danger");
            // this.$router.push({ name: 'misc-error-404' })
          }
        })
        .catch(error => {
          //  console.log('error',error)
          this.submitProcessing = false;
          this.errorsHandler(error);
          // this.$router.push({ name: 'misc-error-404' })

          let msg = this.$responseSolve(error, true, "danger");
          //  self.errorsres = this.$errorsres(error);
          //  this.flash.level = "danger";
          //  this.flash.message = msg;
          // this.$makeToast("danger", msg, this.$t("lang.failed"));

          // if(error.response && error.response.data && error.response.data.errors){
          //   self.errorsres = error.response.data.errors;
          // }
          this.$refs.loginForm.setErrors(error.response.data.errors);
        });
    }
  }
};
</script>
